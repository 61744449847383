export const zipCodes = [
  {
    label: "Area 1",
    deliveryPrice: 80,
    zipCodes: [
      84004, 84003, 84604, 84660, 84013, 84062, 84005, 84043, 84655, 84633,
      84626, 84651, 84045, 84042, 84664, 84057, 84058, 84059, 84097, 84653,
      84601, 84602, 84603, 84605, 84606, 84663,
    ],
  },
  {
    label: "Area 2",
    deliveryPrice: 100,
    zipCodes: [
      84116, 84093, 84044, 84065, 84006, 84047, 84121, 84171, 84020, 84129,
      84070, 84092, 84096, 84117, 84124, 84118, 84128, 84106, 84107, 84109,
      84123, 84157, 84084, 84095, 84081, 84101, 84102, 84103, 84104, 84105,
      84108, 84110, 84111, 84112, 84113, 84114, 84115, 84119, 84120, 84122,
      84133, 84138, 84145, 84147, 84151, 84152, 84158, 84180, 84132, 84134,
      84139, 84141, 84143, 84148, 84150, 84184, 84190, 84199, 84090, 84091,
      84094, 84088, 84009, 84165, 84125, 84127, 84130, 84131, 84170, 84126,
    ],
  },
  {
    label: "Area 3",
    deliveryPrice: 120,
    zipCodes: [
      84015, 84010, 84011, 84014, 84016, 84089, 84025, 84404, 84037, 84414,
      84056, 84315, 84401, 84040, 84041, 84054, 84403, 84405, 84201, 84244,
      84402, 84408, 84409, 84412, 84415, 84067, 84087, 84075,
    ],
  },
  {
    label: "Area 4",
    deliveryPrice: 140,
    zipCodes: [
      84335, 84074, 84029, 84098, 84328, 84071, 84306, 84321, 84339, 84302,
      84304, 84032, 84036, 84309, 84337, 84314, 84318, 84341, 84319, 84049,
      84322, 84323, 84324, 84325, 84326, 84332, 84645, 84648, 84327, 84055,
      84060, 84068, 84340,
    ],
  },
];

export const allDeliverableZipCodes = zipCodes.reduce(
  (acc, curr) => acc.concat(curr.zipCodes),
  []
);
