import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const IconArea = () => {
  return (
    <StaticImage
      src="../images/icon-area.png"
      alt="icon area"
      layout="fixed"
      width={50}
      height={50}
    />
  );
};

export const IconSize = () => {
  return (
    <StaticImage
      src="../images/icon-size.png"
      alt="icon size"
      layout="fixed"
      width={50}
      height={50}
    />
  );
};

export const IconQuantity = () => {
  return (
    <StaticImage
      src="../images/icon-quantity.png"
      alt="icon quantiy"
      layout="fixed"
      width={50}
      height={50}
    />
  );
};

export const IconCart = () => {
  return (
    <StaticImage
      src="../images/icon-cart.png"
      alt="icon cart"
      layout="fixed"
      width={50}
      height={50}
    />
  );
};
