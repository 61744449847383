import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ServiceAreaForm from "../../components/partials/service-areas";

function ServiceAreasPageTemplate(props) {
  return (
    <Layout>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Service",
          "serviceType": "Window Well Covers",
          "areaServed": [
            {
              "@type": "City",
              "name": "Salt Lake City",
              "sameAs": "https://en.wikipedia.org/wiki/Salt_Lake_City"
            },
            {
              "@type": "City",
              "name": "West Valley",
              "sameAs": "https://en.wikipedia.org/wiki/West_Valley,_Utah"
            },
            {
              "@type": "City",
              "name": "Provo",
              "sameAs": "https://en.wikipedia.org/wiki/Provo,_Utah"
            },
            {
              "@type": "City",
              "name": "West Jordan",
              "sameAs": "https://en.wikipedia.org/wiki/West_Jordan,_Utah"
            },
            {
              "@type": "City",
              "name": "Sandy",
              "sameAs": "https://en.wikipedia.org/wiki/Sandy,_Utah"
            },
            {
              "@type": "City",
              "name": "Orem",
              "sameAs": "https://en.wikipedia.org/wiki/Orem,_Utah"
            },
            {
              "@type": "City",
              "name": "Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Layton",
              "sameAs": "https://en.wikipedia.org/wiki/Layton,_Utah"
            },
            {
              "@type": "City",
              "name": "South Jordan",
              "sameAs": "https://en.wikipedia.org/wiki/South_Jordan,_Utah"
            },
            {
              "@type": "City",
              "name": "Taylorsville",
              "sameAs": "https://en.wikipedia.org/wiki/Taylorsville,_Utah"
            },
            {
              "@type": "City",
              "name": "Millcreek",
              "sameAs": "https://en.wikipedia.org/wiki/Millcreek,_Utah"
            },
            {
              "@type": "City",
              "name": "Lehi",
              "sameAs": "https://en.wikipedia.org/wiki/Lehi,_Utah"
            },
            {
              "@type": "City",
              "name": "Murray",
              "sameAs": "https://en.wikipedia.org/wiki/Murray,_Utah"
            },
            {
              "@type": "City",
              "name": "Draper",
              "sameAs": "https://en.wikipedia.org/wiki/Draper,_Utah"
            },
            {
              "@type": "City",
              "name": "Bountiful",
              "sameAs": "https://en.wikipedia.org/wiki/Bountiful,_Utah"
            },
            {
              "@type": "City",
              "name": "Riverton",
              "sameAs": "https://en.wikipedia.org/wiki/Riverton,_Utah"
            },
            {
              "@type": "City",
              "name": "Spanish Fork",
              "sameAs": "https://en.wikipedia.org/wiki/Spanish_Fork,_Utah"
            },
            {
              "@type": "City",
              "name": "Roy",
              "sameAs": "https://en.wikipedia.org/wiki/Roy,_Utah"
            },
            {
              "@type": "City",
              "name": "Pleasant Grove",
              "sameAs": "https://en.wikipedia.org/wiki/Pleasant_Grove,_Utah"
            },
            {
              "@type": "City",
              "name": "Kearns",
              "sameAs": "https://en.wikipedia.org/wiki/Kearns,_Utah"
            },
            {
              "@type": "City",
              "name": "Cottonwood Heights",
              "sameAs": "https://en.wikipedia.org/wiki/Cottonwood_Heights,_Utah"
            },
            {
              "@type": "City",
              "name": "Tooele",
              "sameAs": "https://en.wikipedia.org/wiki/Tooele,_Utah"
            },
            {
              "@type": "City",
              "name": "Springville",
              "sameAs": "https://en.wikipedia.org/wiki/Springville,_Utah"
            },
            {
              "@type": "City",
              "name": "Midvale",
              "sameAs": "https://en.wikipedia.org/wiki/Midvale,_Utah"
            },
            {
              "@type": "City",
              "name": "Herriman",
              "sameAs": "https://en.wikipedia.org/wiki/Herriman,_Utah"
            },
            {
              "@type": "City",
              "name": "Holladay",
              "sameAs": "https://en.wikipedia.org/wiki/Holladay,_Utah"
            },
            {
              "@type": "City",
              "name": "Clearfield",
              "sameAs": "https://en.wikipedia.org/wiki/Clearfield,_Utah"
            },
            {
              "@type": "City",
              "name": "Kaysville",
              "sameAs": "https://en.wikipedia.org/wiki/Kaysville,_Utah"
            },
            {
              "@type": "City",
              "name": "American Fork",
              "sameAs": "https://en.wikipedia.org/wiki/American_Fork,_Utah"
            },
            {
              "@type": "City",
              "name": "Eagle Mountain",
              "sameAs": "https://en.wikipedia.org/wiki/Eagle_Mountain,_Utah"
            },
            {
              "@type": "City",
              "name": "Saratoga Springs",
              "sameAs": "https://en.wikipedia.org/wiki/Saratoga_Springs,_Utah"
            },
            {
              "@type": "City",
              "name": "Farmington",
              "sameAs": "https://en.wikipedia.org/wiki/Farmington,_Utah"
            },
            {
              "@type": "City",
              "name": "Payson",
              "sameAs": "https://en.wikipedia.org/wiki/Payson,_Utah"
            },
            {
              "@type": "City",
              "name": "North Salt Lake",
              "sameAs": "https://en.wikipedia.org/wiki/North_Salt_Lake,_Utah"
            },
            {
              "@type": "City",
              "name": "North Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/North_Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Clinton",
              "sameAs": "https://en.wikipedia.org/wiki/Clinton,_Utah"
            },
            {
              "@type": "City",
              "name": "Highland",
              "sameAs": "https://en.wikipedia.org/wiki/Highland,_Utah"
            },
            {
              "@type": "City",
              "name": "South Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/South_Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Heber",
              "sameAs": "https://en.wikipedia.org/wiki/Heber,_Utah"
            },
            {
              "@type": "City",
              "name": "Bluffdale",
              "sameAs": "https://en.wikipedia.org/wiki/Bluffdale,_Utah"
            },
            {
              "@type": "City",
              "name": "Woodscross",
              "sameAs": "https://en.wikipedia.org/wiki/Woodscross,_Utah"
            },
            {
              "@type": "City",
              "name": "Lindon",
              "sameAs": "https://en.wikipedia.org/wiki/Lindon,_Utah"
            },
            {
              "@type": "City",
              "name": "Cedar Hills",
              "sameAs": "https://en.wikipedia.org/wiki/Cedar_Hills,_Utah"
            },
            {
              "@type": "City",
              "name": "Slpine",
              "sameAs": "https://en.wikipedia.org/wiki/Slpine,_Utah"
            },
            {
              "@type": "City",
              "name": "Park City",
              "sameAs": "https://en.wikipedia.org/wiki/Park_City,_Utah"
            },
            {
              "@type": "City",
              "name": "Fairfield",
              "sameAs": "https://en.wikipedia.org/wiki/Fairfield,_Utah"
            },
            {
              "@type": "City",
              "name": "Stockton",
              "sameAs": "https://en.wikipedia.org/wiki/Stockton,_Utah"
            },
            {
              "@type": "City",
              "name": "Kamas",
              "sameAs": "https://en.wikipedia.org/wiki/Kamas,_Utah"
            },
            {
              "@type": "City",
              "name": "Tremonton",
              "sameAs": "https://en.wikipedia.org/wiki/Tremonton,_Utah"
            },
            {
              "@type": "City",
              "name": "Logan",
              "sameAs": "https://en.wikipedia.org/wiki/Logan,_Utah"
            }
          ]
        }
      `}</script>
      </Helmet>
      <div className="container">
        <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
          <h1
            className="m-auto"
            dangerouslySetInnerHTML={{
              __html: props.currentPage.pages.h1Title
                ? props.currentPage.pages.h1Title
                : props.currentPage.title,
            }}
          />
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-8 pb-16">
          <div className="mb-8">
            <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1Rha4vJgxSDCn-nGT7ZQIuFKCC0qnkB8&ehbc=2E312F&noprof=1"
              width="640"
              height="480"
            ></iframe>
          </div>
          <div className=" text-grey-darker">
            <ServiceAreaForm showIntro={false} />
            <div
              dangerouslySetInnerHTML={{
                __html: props.currentPage.content,
              }}
              className="wp-content"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default ServiceAreasPageTemplate;
