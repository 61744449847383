import { Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { allDeliverableZipCodes } from "../../data/zip-codes";
import { IconArea } from "../icons";
import { zipCodes } from "../../data/zip-codes";
import CartContext from "../../context/cart-context";

const ServiceAreaForm = ({ showIntro = true }) => {
  const cartContext = useContext(CartContext);
  const formikRef = useRef(null);

  useEffect(() => {
    async function validate() {
      await formikRef.current.validateForm();
    }
    validate();
  }, []);

  const shippingInfo = useMemo(() => {
    return zipCodes.find((area) =>
      area.zipCodes.includes(Number(cartContext.checkoutInfo.shippingZip))
    );
  }, [cartContext]);

  return (
    <div>
      <h3 className="font-semibold text-xl mt-0 mb-2">
        Are you in our service area
      </h3>
      {showIntro && (
        <p className="leading-loose mb-3">
          Enter your zip code to find out more about our service area, or read
          more here:{" "}
          <Link
            to="/service-areas/"
            className={`text-${process.env.THEME_COLOR_PRIMARY} underline`}
          >
            Window Well Covers in the Utah County, Ogden & Salt Lake Areas
          </Link>
        </p>
      )}

      <Formik
        innerRef={formikRef}
        initialValues={{
          shippingZip: cartContext.checkoutInfo.shippingZip ?? "",
        }}
        validate={(values) => {
          let errors = {};

          if (!values.shippingZip) {
            errors.shippingZip = "Required";
          } else if (values.shippingZip.length !== 5) {
            errors.shippingZip = "Please enter a valid zip code";
          } else if (
            allDeliverableZipCodes.indexOf(parseInt(values.shippingZip)) < 0
          ) {
            errors.shippingZip = `We do not currently deliver to that area. You can still order and pick up your order from our ${process.env.ADDRESS_CITY} location. Please call us for more information.`;
          }

          cartContext.setCheckoutInfo({
            ...cartContext.checkoutInfo,
            ...values,
          });

          return errors;
        }}
        onSubmit={(values) => {
          console.log(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors }) => (
          <Form>
            <div className="flex items-center justify-between rounded-lg border border-grey p-4 mb-4">
              <div className="flex items-center gap-3">
                <IconArea />
                <div className="block text-sm">
                  <span className="block text-black font-bold text-base">
                    Zip Code
                  </span>
                  <span className="block text-grey-dark">
                    Enter your zip for service area
                  </span>
                </div>
              </div>
              <Field
                type="text"
                name="shippingZip"
                placeholder="Enter your zip code"
                className="appearance-none focus:outline-none focus:ring border border-grey rounded-lg px-4 py-3"
              />
            </div>
            {!errors.shippingZip &&
              values.shippingZip.length === 5 &&
              shippingInfo && (
                <div
                  className={`text-${process.env.THEME_COLOR_PRIMARY}-darker`}
                >
                  <p>Pick Up - Free</p>
                  <p>
                    {shippingInfo.label} Delivery - $
                    {shippingInfo.deliveryPrice}
                  </p>
                  <p>
                    {shippingInfo.label} Delivery and Installation - $
                    {shippingInfo.deliveryPrice + 30}
                  </p>
                </div>
              )}
            {values.shippingZip.length === 5 && errors.shippingZip && (
              <p className="text-orange-dark w-full md:w-1/2  px-3 lg:w-full">
                We do not currently deliver to that area. You can still order
                and pick up your order from our {process.env.ADDRESS_CITY}{" "}
                location. Please{" "}
                <Link to="/contact-us/" className="text-orange-dark">
                  call us
                </Link>{" "}
                for more information.
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceAreaForm;
